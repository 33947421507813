import React from "react"
import { Link } from "gatsby"

// @ts-ignore
import * as styles from "./ReportNav.module.css"

function ReportNav() {
  return (
    <nav className={styles.nav}>
      <div className={styles.title}>
        <h1>PARC Reports</h1>
      </div>
      <div className={styles.items}>
        <ul>
          <li><Link activeClassName="report-nav-active" to="/reports/abstracts">Abstracts</Link></li>
          <li><Link activeClassName="report-nav-active" to="/reports/registrations">Registrations</Link></li>
          <li><Link activeClassName="report-nav-active" to="/reports/sponsors">Sponsors</Link></li>
          <li><Link activeClassName="report-nav-active" to="/reports/exhibitors">Exhibitors</Link></li>
          <li><Link activeClassName="report-nav-active" to="/reports/alacarte">AlaCarte</Link></li>
          <li><Link to="/">Exit</Link></li>
        </ul>
      </div>
    </nav>
  )
}

export default ReportNav