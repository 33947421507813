import React, { createRef, useState, useEffect } from "react"
import { Helmet } from "react-helmet"

import { BasicGlobalLayout } from "../../components/layout/globalLayout/GlobalLayout"
import LoginOverlay from "../../components/internal/loginOverlay/LoginOverlay"
import ExhibitorReportTable from "../../components/reports/exhibitorReportTable/ExhibitorReportTable"
import ReportNav from "../../components/reports/reportNav/ReportNav"

// @ts-ignore
import * as formStyles from "../../components/reports/TableStyles.module.css"

function ExhibitorReportPage() {
  const [loginLoading,setLoginLoading] = useState<boolean>(false),
        [loginError,setLoginError] = useState<string>(),
        [isAuthenticated,setIsAuthenticated] = useState<boolean>(false),
        passwordRef = createRef<HTMLInputElement>()

  function saveToken() {
    if(window && window.localStorage) {
      let expDate = Date.now()

      // 15 minute authentication limit
      expDate += ((15*60)*1000)
      window.localStorage.setItem('parc-auth-token', `${expDate}`)
    }
  }

  function checkToken() {
    if(window && window.localStorage) {
      let raw = parseInt(window.localStorage.getItem('parc-auth-token') || "0")
      if(raw) {
        let now = Date.now()
        if(now < raw) {
          setIsAuthenticated(true)
        }
      }
    }
  }

  function onLoginSubmit(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setLoginError("")
    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/reports`, {
      method: "POST",
      body: JSON.stringify({password: passwordRef.current?.value || ""})
    })
    .then((response)=>{
      response.text()
      .then((data:string)=>{
        let response = JSON.parse(data)
        setLoginLoading(false)
        if(response.success) {
          setIsAuthenticated(true)
          saveToken()
        } else {
          console.warn("not authenticated: ", response.message)
          setLoginError("Invalid Password")
        }
      })
      .catch((err)=>{
        console.error(err)
        setLoginLoading(false)
        setLoginError("Failed to authenticate")
      })
    })
    .catch((err)=>{
      console.error(err)
      setLoginLoading(false)
      setLoginError("Failed to authenticate")
    })
  }

  useEffect(()=>{
    checkToken()
  },[])

  return (
    <BasicGlobalLayout>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
        <meta name="robots" content="nosnippet" />
        <meta name="robots" content="noarchive" />
        <meta name="robots" content="notranslate" />
      </Helmet>
      <ReportNav />
      <h3 className={formStyles.subheader}>Exhibitor Report</h3>
      { isAuthenticated  ? (
        <div>
          <ExhibitorReportTable />
        </div>
      ) : (
        <div>
          <p>You must be authenticated to view this content</p>
          <LoginOverlay isLoading={loginLoading} errorMessage={loginError} passwordRef={passwordRef} onSubmit={onLoginSubmit} />
        </div>
      ) }
    </BasicGlobalLayout>
  )
}

export default ExhibitorReportPage