import React from "react"

// @ts-ignore
import * as styles from "./LoginOverlay.module.css"

interface LoginOverlayProps {
  isLoading: boolean,
  errorMessage: string|undefined,
  passwordRef:React.RefObject<HTMLInputElement>,
  onSubmit:React.FormEventHandler<HTMLFormElement>
}
function LoginOverlay({ isLoading, errorMessage, passwordRef, onSubmit }:LoginOverlayProps) {

  return(
    <div className={styles.wrapper}>
      <div className={styles.overlay}></div>
      <div className={styles.formContainer} > 
        <form onSubmit={onSubmit}>
          <h3>This is protected content. Please enter the password.</h3>
          <br/>
          {errorMessage ? <p className={styles.error}>{errorMessage}</p> : <p></p>}
          <div className="form-group text_box">
            <label htmlFor="password">Password</label>
            <input id="password" disabled={isLoading} type="password" placeholder="***" ref={passwordRef} />
          </div>
          <div className={styles.submit}>
            <button className="parc-btn orange solid" disabled={isLoading} type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LoginOverlay