import React, { useState, useEffect, useMemo } from "react"

import { FormatCurrency } from "../../../utils/utils"

// @ts-ignore
import * as styles from "../TableStyles.module.css"
import { MONTH_YEAR_ROLLOVER, GetYearFilterOptions, GetDefaultSelectedYear, FilterRecords } from "../../../../utils/reports"


function ExhibitorReportTable():JSX.Element {
  const [records,setRecords] = useState<Array<ExhibitorRegistrationRecord>>([]),
        [isLoading,setIsLoading] = useState<boolean>(false),
        [errorMessage,setErrorMessage] = useState<string>(""),
        [yearOptions,setYearOptions] = useState<Array<number>>([]),
        [filteredYear,setFilteredYear] = useState<number>()

  function loadRecords () {
    setIsLoading(true)
    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/reports?t=exhibitors`, {
      method: "GET"
    })
    .then((response)=>{
      response.text()
      .then((data:string)=>{
        let response = JSON.parse(data)
        let newRecords = response.data.map((ref:any)=>{
          return {
            ...ref.data,
            last_modified: new Date(ref.ts/1000)
          }
        }).filter((a:any) => { return a ? true : false})
          .filter((a:any) => { return a.payment_status && a.payment_status === "paid" ? true : false})

        setRecords(newRecords)
        setFilteredYear(GetDefaultSelectedYear())
        setYearOptions(GetYearFilterOptions())
        setIsLoading(false)
      })
      .catch((err)=>{
        setIsLoading(false)
        setErrorMessage("Failed to load records")
      })
    })
    .catch((err)=>{
      setIsLoading(false)
      setErrorMessage("Failed to load records")
    })
  }

  useEffect(()=>{
    loadRecords()
  }, [])

  function copyData() {
    let data = records.map((record) => {
      const exhibitorData = record as ExhibitorRegistrationRecord
      return [
        exhibitorData.last_modified?.toLocaleString(),
        exhibitorData.form_type,
        exhibitorData.firstName,
        FormatCurrency(exhibitorData.amount_charged || 0),
        exhibitorData.lastName,
        exhibitorData.affiliation,
        exhibitorData.address1,
        exhibitorData.address2,
        exhibitorData.city,
        exhibitorData.state,
        exhibitorData.zip,
        exhibitorData.country,
        exhibitorData.telephone,
        exhibitorData.email,
        exhibitorData.registrationType,
        exhibitorData.exhibitor_first_name,
        exhibitorData.exhibitor_last_name,
        exhibitorData.willAttendWelcomeReception ? "Yes" : "No",
        exhibitorData.additionalWelcomeReceptionGuestsCount,
        exhibitorData.willAttendBanquet ? "Yes" : "No",
        exhibitorData.additionalBanquetAdultMeals,
        exhibitorData.additionalBanquetChildMeals,
      ].join('\t')
    }).join('\n')
    navigator.clipboard.writeText(data)
  }

  function onUpdateFilter(e:React.ChangeEvent<HTMLSelectElement> ) {
    try {
      let newYear :number|undefined
      newYear = parseInt(e.target.value)
      if(newYear && newYear > 2020) {
        setFilteredYear(newYear)
        return
      }
    } catch(err) {
    }
    setFilteredYear(undefined)
  }

  const visibleResults:Array<FormRecord> = useMemo(()=>{
    return FilterRecords(records, filteredYear)
  },[yearOptions,filteredYear,records])


  return (
    <div>
      <div className={styles.infoBar}>
        <div>
          <button onClick={copyData}>Copy</button>
          <p style={{paddingLeft: 10}}>{`${visibleResults.length} Records`}</p>
        </div>
        {isLoading ? (<p>Loading...</p>) : null}
        <div>
          <span className={styles.field}>
            <label title={`Years rollover on the ${MONTH_YEAR_ROLLOVER+1} month `}>Year</label>
            <select value={filteredYear} onChange={onUpdateFilter}>
              <option value="">All</option>
              {yearOptions.map((yr)=>(
                <option key={yr} value={yr}>{`${MONTH_YEAR_ROLLOVER+1}/${yr-1} through ${MONTH_YEAR_ROLLOVER}/${yr}`}</option>
              ))}
            </select>
          </span>
        </div>
      </div>
      {errorMessage ? (<p>{errorMessage}</p> ) : null}
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Form</th>
              <th>Type</th>
              <th>Amount Charged</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Affiliation</th>
              <th>Address 1</th>
              <th>Address 2</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th>Country</th>
              <th>Telephone</th>
              <th>Email</th>
              <th>Type</th>
              <th>Exhibitor First Name</th>
              <th>Exhibitor Last Name</th>
              <th>Attend Reception</th>
              <th>Reception Additional Guests</th>
              <th>Attend Banquet</th>
              <th>Extra Adult Meals</th>
              <th>Extra Child Meals</th>
            </tr>
          </thead>
          <tbody>
            {visibleResults.map((record)=>{
              let d = record as ExhibitorRegistrationRecord
              return (
                <tr key={d.client_reference_id}>
                  <td>{record.last_modified?.toLocaleString()}</td>
                  <td>{d.form_type}</td>
                  <td>{d.registrationType}</td>
                  <td>{FormatCurrency(d.amount_charged || 0)}</td>
                  <td>{d.firstName}</td>
                  <td>{d.lastName}</td>
                  <td>{d.affiliation}</td>
                  <td>{d.address1}</td>
                  <td>{d.address2}</td>
                  <td>{d.city}</td>
                  <td>{d.state}</td>
                  <td>{d.zip}</td>
                  <td>{d.country}</td>
                  <td>{d.telephone}</td>
                  <td>{d.email}</td>
                  <td>{d.registrationType}</td>
                  <td>{d.exhibitor_first_name}</td>
                  <td>{d.exhibitor_last_name}</td>
                  <td>{d.willAttendWelcomeReception ? "Yes" : "No"}</td>
                  <td>{d.additionalWelcomeReceptionGuestsCount}</td>
                  <td>{d.willAttendBanquet ? "Yes" : "No"}</td>
                  <td>{d.additionalBanquetAdultMeals}</td>
                  <td>{d.additionalBanquetChildMeals}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ExhibitorReportTable