
// Filters by year will push records to next year if month is >= this value
const MONTH_YEAR_ROLLOVER:0|1|2|3|4|5|6|7|8|9|10|11 = 8; // September=8
const YEAR_MIN = 2022


function GetYearFilterOptions():Array<number> {
  let years = []
  let now = new Date()
  let currentYear = now.getFullYear()
  if(now.getMonth() >= MONTH_YEAR_ROLLOVER) {
    currentYear++
  }
  while(currentYear >= YEAR_MIN) {
    years.push(currentYear)
    currentYear--
  }
  return years
}

function GetDefaultSelectedYear():number {
  let now = new Date()
  let currentYear = now.getFullYear()
  if(now.getMonth() >= MONTH_YEAR_ROLLOVER) {
    currentYear++
  }
  return currentYear
}

function FilterRecords(records?:Array<FormRecord>,year?:number):Array<FormRecord> {
  if(!year) {
    return records || []
  }
  if(records) {
    return records.filter((r:FormRecord)=>{
      if(r.last_modified) {
        let rYear = r.last_modified.getFullYear()
        if(r.last_modified.getMonth() >= MONTH_YEAR_ROLLOVER) {
          rYear++
        }
        return rYear === year
      }
      return false
    })
  }
  return []
}

export {
  MONTH_YEAR_ROLLOVER,
  GetYearFilterOptions,
  GetDefaultSelectedYear,
  FilterRecords
}