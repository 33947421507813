
let currencyFormatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' })
function FormatCurrency(amount:number):string {
  return currencyFormatter.format(amount)
}


function GetDateFromStoryblokDate(date:string|number):Date {
  if(typeof date === "string") {
    return new Date(date.replace(/-/g, "/"))
  }
  return new Date(date)
}
// Decides wether or not a storyblok link field has a value
function isLinkDefined(link: StoryblokRef.Link) {
  if (!link) return false;
  switch (link.linktype) {
    case 'story':
      return link.id || link.cached_url ? true : false;
    case 'asset':
    case 'url':
      return link.url ? true : false;
    case 'email':
      return link.email ? true : false;
    default:
      return link.cached_url ? true : false;
  }
}
// Decides wether or not a storyblok asset field has a value
function isAssetDefined(asset: StoryblokRef.Image) {
  if (!asset) {
    return false;
  } else {
    if (!asset.filename) {
      return false;
    }
  }
  return true;
}


export { FormatCurrency, GetDateFromStoryblokDate, isLinkDefined, isAssetDefined }